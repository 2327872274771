import shopifyWarehouseDefaults from '../shopify-warehouse-generic/config.js';

shopifyWarehouseDefaults.Widgets.map((w) => {
  if (w.name === 'FilterChips' || w.name === 'FacetPanel' || w.name === 'FacetDialog') {
    if (w.ignoreFields) {
      w.ignoreFields.push('manufacturers');
    } else {
      w.ignoreFields = ['manufacturers'];
    }
  }

  return w;
});

const baseUpdateCallback = shopifyWarehouseDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
)?.updateCallback;

const updateCallback = () => {
  baseUpdateCallback?.();

  const universalSeparator = window.document.querySelector('.cm_universal-separator');
  const universalItems = window.document.querySelectorAll('.cm_universal-item');
  const vehicleSpecificItems = window.document.querySelectorAll('.cm_vehicle-specific-item');

  if (universalItems.length > 0 && vehicleSpecificItems.length > 0) {
    universalSeparator.classList.remove('cm_hidden');
  } else {
    universalSeparator.classList.add('cm_hidden');
  }
};

export default {
  includes: ['shopify-warehouse-generic'],
  ...shopifyWarehouseDefaults,
  pagination: {
    centralRange: 1,
    edgeRange: 1,
  },
  Widgets: [
    ...shopifyWarehouseDefaults.Widgets.filter((w) => !['SearchResult'].includes(w.name)),
    {
      name: 'SearchResult',
      updateCallback,
    },
  ],
};
